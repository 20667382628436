import React from "react";
import { graphql } from "gatsby";
import { PrismicRichText, SliceZone } from "@prismicio/react";
import { Container } from "react-bootstrap";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import PageBanner from "../components/elements/PageBanner";
import components from "../components/slices/index";
import { constructActiveDoc } from "../utilities/helpers";
import "../styles/pages/loan_page.scss";

const LoanPage = ({ data }) => {
  if (!data) return null;
  // console.log(data.page.type);

  const { page } = data;
  // const { title, content } = page.data;

  return (
    <div className={data.page.type}>
      <Seo page={page} />
      <Layout activeDocMeta={constructActiveDoc(page)}>
        {/* <div className="custom-wrapper"> */}
        <PageBanner
          title={page.data.title}
          bgImage={page.data.banner_image}
          bgImageMobile={page.data.image_mobile}
          page={page}
        />
        <SliceZone components={components} slices={page.data.body} />
        {/* </div> */}
      </Layout>
    </div>
  );
};

export const query = graphql`
  query loanPageQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicLoanPage(lang: { eq: $lang }, id: { eq: $id }) {
      ...prismicLoanPageFragment
    }
  }
`;

export default LoanPage;
